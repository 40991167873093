import { hot } from 'react-hot-loader/root'
import React from 'react'
import styled from 'styled-components'
import 'normalize.css'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import { ThemeProvider, GlobalStyle, theme } from './Theme'
import { AuthProvider } from './contexts/AuthContext'
import { ModalProvider, BaseModalBackground } from 'styled-react-modal'

import { Footer } from './components'

import Navigation from './modules/Navigation'
import ProtectedRoute from './modules/ProtectedRoute'
import NotForMobileWrapper from './modules/NotForMobileWrapper'

import Home from './pages/Home'
import Import from './pages/Import'
import Login from './pages/Login'
import Manage from './pages/Manage'
import OCR from './pages/OCR'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Account from './pages/Account'
import Export from './pages/Export'
import Logout from './pages/Logout'
import Register from './pages/Register'
import Help from './pages/Help'
import Teams from './pages/Team'


const StyledModalBackground = styled(BaseModalBackground)`
  z-index: 10000;
`

const App = () => {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ModalProvider backgroundComponent={StyledModalBackground}>
            <NotForMobileWrapper>
              <Navigation />
              <div style={{ flex: '1 0 auto' }}>
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/register" component={Register} />
                  <Route exact path="/forgot-password" component={ForgotPassword} />
                  <Route exact path="/reset-password" component={ResetPassword} />
                  <Route exact path="/help" component={Help} />
                  <ProtectedRoute exact path="/home" component={Home} />
                  <ProtectedRoute exact path="/import" component={Import} />
                  <ProtectedRoute exact path="/ocr" component={OCR} />
                  <ProtectedRoute exact path="/export" component={Export} />
                  <ProtectedRoute exact path="/logout" component={Logout} />
                  <ProtectedRoute path="/account/:resource" component={Account} />
                  <ProtectedRoute exact path="/teams" component={Teams} />
                  <ProtectedRoute path="/manage/:resource" component={Manage} />
                  <Route path="*">
                    <Redirect to="/home" />
                  </Route>
                </Switch>
              </div>
            </NotForMobileWrapper>
            <Footer />
          </ModalProvider>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default hot(App)
