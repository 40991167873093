import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  BlankButton,
  Icon,
  CloseCircleFilledIcon,
  TeamIcon,
} from '../../components'


const StyledTeam = styled.div`
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  margin:  0.5rem;
  padding:  0.5rem;

  &:hover {
    background-color: buttonface;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
  }
`

const StyledName = styled.strong`
  flex: 1;
  display: inline-flex;
  align-items: center;
  overflow: auto;
`

const TeamIconWrapper = styled.span`
  line-height: 0;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: .5rem;
`

const StyledTeamIcon = styled(TeamIcon)`
  width: 1.8rem;
  height: 1.8rem;
`

const StyledInfos = styled.div`
  font-size: .75rem;
  color: ${({ theme }) => theme.dustyGray};
`

const StyledButton = styled(BlankButton)`
  display: inline-flex;
  float: right;
  color: ${({ theme, primary, danger }) =>
    (primary && theme.primaryColor) ||
    (danger && theme.dangerRed)
  };

  ${Icon} {
    width: 1.25rem;
    height: 1.25rem;
    justify-content: center;
    align-items: center;
  }
`

export const Team = ({ team, onClickDelete, canEdit, onClick }) => {
  return (
    <StyledTeam onClick={() => onClick(team)}>
      <StyledName>
        <TeamIconWrapper>
          <StyledTeamIcon />
        </TeamIconWrapper>
        <h2 style={{ margin: '0' }}>{team.name}</h2>
      </StyledName>
      {canEdit && <StyledButton danger onClick={onClickDelete}>
        <CloseCircleFilledIcon />
      </StyledButton>}
      <StyledInfos>
        {team.teammates.length} collaborateurs
      </StyledInfos>
    </StyledTeam>
  )
}

Team.propTypes = {
  team: PropTypes.object.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func,
}
