import React, { useState, useContext } from 'react'
import styled from 'styled-components'

import AuthContext from '../contexts/AuthContext'
import * as services from '../services'

import {
  Row,
  Columns,
  H1,
  H2,
  H3,
  P,
  TextInput,
  EditIcon,
  Form,
  FormGroup,
  Button,
  SpinnerAnimatedIcon
} from '../components/design-system'

const StyledPage = styled.div`
  max-width: 960px;
  margin: auto;
  text-align: center;
`

const Headings = styled.div`
  margin: 2rem 0px;
`

const StyledH2 = styled(H2)`
  font-family: Blinker;
  font-size: 1.5625rem;
  letter-spacing: 0;
  font-weight: 700;
`

const StyledH3 = styled(H3)`
  font-size: 1.125rem;
  font-family: Blinker;
  font-weight: 700;
  letter-spacing: 0;
  margin: 1rem 0;
`

const StyledForm = styled(Form)`
  margin-bottom: 2rem;
`

const Error = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.errorRed};
`

const ErrorLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.errorRed};

  &:hover {
    cursor: pointer;
  }
`

const ConfirmZone = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.primaryColor};
`

// Source: https://emailregex.com/
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default () => {
  const [{ user }] = useContext(AuthContext)

  const [firstname, setFirstname] = useState(user.firstname)
  const [lastname, setLastname] = useState(user.lastname)
  const [email, setEmail] = useState(user.email)
  const [company, setCompany] = useState(user.company)
  const [employeeId, setEmployeeId] = useState(user.employeeId)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordCheck, setNewPasswordCheck] = useState('')

  const [onRequest, setOnRequest] = useState(false)
  const [onError, setOnError] = useState(false)
  const [onRequestError, setOnRequestError] = useState(null)
  const [updated, setUpdated] = useState(false)

  const showError = () => {
    if (onError) {
      return (
        <Error>
          {(firstname.length <= 0) ? <>Prénom requis<br /></> : null}
          {(lastname.length <= 0) ? <>Nom requis<br /></> : null}
          {(email.length <= 0) ? <>Email requis<br /></> : null}
          {!EMAIL_REGEX.test(email) ? <>Email format incorrect<br /></> : null}
          {((newPassword || newPasswordCheck) && !oldPassword) ? <>Ancien mot de passe requis<br /></> : null}
          {(oldPassword && newPassword && !newPasswordCheck) ? <>Confirmation du mot de passe requise<br /></> : null}
          {(newPassword && newPasswordCheck && newPassword !== newPasswordCheck) ? <>Confirmation du mot de passe incorrecte<br /></> : null}
        </Error>
      )
    } else if (onRequestError === 409) {
      return (
        <Error>
          Un compte utilisateur avec cet email existe déja
        </Error>
      )
    } else if (onRequestError === 422) {
      return (
        <Error>
          La confirmation de votre mot de passe actuel a échoué
        </Error>
      )
    } else if (
      onRequestError === 500
      || onRequestError === 412
      || onRequestError === 404
      || onRequestError === 401
    ) {
      return (
        <Error>
          Une erreur est survenue, réessayez plus tard ou contactez un administrateur via ce <ErrorLink target='_blank' href='https://neurocr.ai/contact'>formulaire de contact</ErrorLink>
        </Error>
      )
    }

    return null
  }

  const handleSubmit = e => {
    setOnRequest(true)
    setOnError(false)
    setOnRequestError(null)
    setUpdated(false)

    e.preventDefault()
    e.stopPropagation()

    if (
      firstname.length <= 0
      || lastname.length <= 0
      || email.length <= 0
      || !EMAIL_REGEX.test(email)
      || ((newPassword || newPasswordCheck) && !oldPassword)
      || (oldPassword && newPassword && !newPasswordCheck)
      || (newPassword && newPasswordCheck && newPassword !== newPasswordCheck)
    ) {
      setOnRequest(false)
      setOnError(true)

      return false
    }

    setOnRequest(true)
    setOnRequestError(false)
    services
      .updateUser(user._id, {
        firstname,
        lastname,
        email,
        company,
        employeeId,
        oldPassword: oldPassword,
        newPassword: newPassword,
      }).then(() => {
        setOnRequest(false)
        setUpdated(true)
      })
      .catch(err => {
        console.error(err)

        if (err.response && err.response.status)
          setOnRequestError(err.response.status)

        setOnRequest(false)
      })
  }

  return (
    <StyledPage>
      <Headings>
        <H1>{user.firstname} {user.lastname}</H1>
        {user.type === 'admin' && <StyledH2>Administateur</StyledH2>}
      </Headings>
      <StyledForm onSubmit={handleSubmit}>
        <Row>
          <Columns nb={6}>
            <FormGroup>
              <TextInput
                required
                buttonIcon={EditIcon}
                value={firstname}
                onChange={setFirstname}
                label='Prénom'
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                required
                buttonIcon={EditIcon}
                value={lastname}
                onChange={setLastname}
                label='Nom'
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                required
                buttonIcon={EditIcon}
                value={email}
                onChange={setEmail}
                label='Email'
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                buttonIcon={EditIcon}
                value={company}
                onChange={setCompany}
                label='Entreprise'
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                buttonIcon={EditIcon}
                value={employeeId}
                onChange={setEmployeeId}
                label='N° de poste'
              />
            </FormGroup>
          </Columns>
          <Columns nb={6}>
            <StyledH3>Modifier le mot de passe</StyledH3>
            <FormGroup>
              <TextInput
                value={oldPassword}
                onChange={setOldPassword}
                type='password'
                label='Mot de passe actuel'
                required={newPassword !== '' || newPasswordCheck !== ''}
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                value={newPassword}
                onChange={setNewPassword}
                type='password'
                label='Nouveau mot de passe'
                autoComplete='new-password'
                required={newPasswordCheck !== ''}
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                validator={v => newPassword && v != newPassword ? 'Différent du nouveau mot de passe' : null}
                value={newPasswordCheck}
                onChange={setNewPasswordCheck}
                type='password'
                label='Vérification nouveau mot de passe'
                autoComplete='new-password'
                required={newPassword !== ''}
              />
            </FormGroup>
          </Columns>
        </Row>
        <Row>
          <FormGroup style={{ justifyContent: 'center' }}>
            <Button
              primary
              type='submit'
              leftIcon={onRequest ? <SpinnerAnimatedIcon /> : null}
              disabled={onRequest}
            >
              Valider
            </Button>
          </FormGroup>
          {showError()}
          {
            updated && (
              <ConfirmZone>
                Modifications enregistrées
              </ConfirmZone>
            )
          }
        </Row>
      </StyledForm>
    </StyledPage>
  )
}
