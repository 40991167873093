import axios from 'axios'

export const fetchAllUsers = () =>
  axios.get('/api/users').then(({ data }) => data)

export const createUser = (infos) =>
  axios
    .post(`/api/users`, infos)
    .then(({ data }) => data)

export const fetchUser = id =>
  axios
    .get(`/api/users/${id}`)
    .then(({ data }) => data)

export const fetchUserByEmail = email =>
  axios
    .get(`/api/users?email=${email}`)
    .then(({ data }) => data)

export const updateUser = (id, updates) =>
  axios
    .put(`/api/users/${id}`, updates)
    .then(({ data }) => data)

export const deleteUser = (id) =>
  axios
    .delete(`/api/users/${id}`)
    .then(({ data }) => data)

export const deletedUser = () =>
  axios
    .get(`/api/users/deletedUsers`)
    .then(({ data }) => data)