import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components'

import AuthContext from '../contexts/AuthContext'
import * as services from '../services'
import useList from '../hooks/useList'

import {
  Row,
  Columns,
  H1,
  H5,
  H4,
  P,
  BlankButton,
  Form,
  FormGroup,
  Radio,
  Button,
  TextInput,
  Icon,
  EditIcon,
  AccountIcon,
  Ul,
} from '../components/design-system'

const StyledPage = styled.div`
  max-width: 960px;
  margin: auto;
`

const StyledColumns = styled(Columns)`
  margin: 0 !important;
  padding-right: 5%;
  border-right: 1px solid gray;

  & + ${Columns} {
    padding-left: 5%;
  }

  &:last-child {
    border-right: 0;
  }
`

const StyledH1 = styled(H1)`
  margin: 2rem 0px;
`

const StyledHeadings = styled.div`
  margin-bottom: 2rem;
`

const SubHeadings = styled.div`
  display: flex;
  align-items: baseline;
`

const StyledH4 = styled(H4)`
  display: inline-block;
  margin: 0 1rem 2rem 0;
`

const StyledH5 = styled(H5)`
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-family: Blinker;
  font-weight: 700;
  letter-spacing: 0;
`

const EditButton = styled(BlankButton)`
  color: ${({ theme }) => theme.primaryColor};
  font-size: .75rem;
`

const CancelButton = styled(BlankButton)`
  color: ${({ theme }) => theme.dangerRed};
  font-size: .75rem;
`

const ResetButton = styled(BlankButton)`
  color: ${({ theme }) => theme.blue};
  font-size: .75rem;
`

const ButtonsGroup = styled(FormGroup)`
  margin-left: 1rem;
`

const Infos = styled(P)`
  text-align: center;

  ${Icon} {
    color: ${({ theme }) => theme.pictonBlue};
    vertical-align: middle;
  }
`

const Description = styled(P)`
  margin-bottom: 1.5rem;
`

const StyledAccountIcon = styled(AccountIcon)`
  vertical-align: middle;
  margin-left: .5rem;
  color: #50C5F3 !important;
`

const Empty = styled.p`
  font-size: .85rem;
  font-style: italic;
`

const labelFeature = {
  "FEATURE_MANAGE_USERS": 'Gestion utilisateur',
  "FEATURE_MANAGE_EXPORT_PROFILE": 'Gestion profil d\'export',
  "FEATURE_MANAGE_DICTIONNARY": 'Gestion des dictionnaires',
  "FEATURE_QUALIFY": 'Qualifier',
  "FEATURE_MANAGE_TEMPLATES": 'Gestion Templates',
  "FEATURE_MANAGE_DOCUMENTS": 'Gestion des documents',
  "FEATURE_MANAGE_TEAMS": 'Gestion d\'équipe',
  "FEATURE_MANAGE_PROJECT": 'Gestion des projets',
  "FEATURE_IMPORT": 'Import',
  "FEATURE_EXPORT": 'Export'
}

export default () => {
  const [{ user: currentUser }] = useContext(AuthContext);
  const [
    { items: users },
    {
      setItem: setUser,
      setItems: setUsers,
      updateItem: updateUser,
      clearItem: clearUsers
    }
  ] = useList();

  const [featureStates, setFeatureStates] = useState([]);
  const [deletedUsers, setDeletedUsers] = useState([]);

  const [toUpdate, setToUpdate] = useState({
    firstname: '',
    lastname: '',
    email: '',
    company: '',
    employeeId: '',
    type: 'user',
    active: false
  })

  useEffect(() => {
    refreshUsers()

    services
      .getEnv()
      .then(({ env }) => {
        const featureToState = [];
        Object.keys(env).map(key => {
          if (env[key]) {
            featureToState.push({
              key: key,
              label: labelFeature[key],
              isActive: env[key]
            })
          }
        })
        setFeatureStates([...featureToState])
      })
  }, [])

  const resetToUpdate = () =>
    setToUpdate({
      firstname: '',
      lastname: '',
      email: '',
      company: '',
      employeeId: '',
      type: 'user',
      active: false
    })

  const refreshUsers = async () => {
    return await services
      .fetchAllUsers()
      .then(async ({ users }) => {
        clearUsers()
        setUsers(users)
        return await services.deletedUser().then((data) => {
          setDeletedUsers(data)
        })
      })
  }

  const handleClickCancel = () => resetToUpdate()
  const handleClickReset = () => resetToUpdate()

  const handleClickDelete = (userId) => {
    services.deleteUser(userId).then(() => {
      resetToUpdate();
      refreshUsers();
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    let toUpdateFeature = {}

    featureStates.map(featureStatesItem => {
      toUpdateFeature[featureStatesItem.key] = featureStatesItem.isActive
    })

    toUpdate.env = toUpdateFeature

    if (toUpdate.id)
      services
        .updateUser(toUpdate.id, toUpdate)
        .then(({ user }) => {
          updateUser(user)
          setToUpdate(user)
          const featureToStateToUpdate = [];
          console.log('user.env : ', user.env)
          Object.keys(user.env).map(key => {
            if (featureStates.find(feature => feature.key)) {
              featureToStateToUpdate.push({
                key: key,
                label: labelFeature[key],
                isActive: user.env[key]
              })
            }
          })
          setFeatureStates([...featureToStateToUpdate])
          refreshUsers();
        })
    else
      services
        .createUser(toUpdate)
        .then(({ user }) => {
          setUser(user)
          setToUpdate(user)
          const featureToState = [];
          Object.keys(user.env).map(key => {
            if (featureToState[key] != null) {
              featureToState.push({
                key: key,
                label: labelFeature[key],
                isActive: user.env[key]
              })
            }
          })
          setFeatureStates([...featureToState])
        })
  }

  function maskString(str, visibleChars = 1) {
    if (str.length === 0) return ""; // Gère le cas d'un string vide
    if (visibleChars >= str.length) return str; // Si on veut afficher tout le string, on le retourne

    return str.substring(0, visibleChars) + '*'.repeat(str.length - visibleChars);
  }

  const usersNotAdmin = users.filter(user => user.type != 'admin')
  const admins = users.filter(user => user.type === 'admin')

  return (
    <StyledPage>
      <StyledHeadings>
        <StyledH1>Gestion des utilisateurs</StyledH1>
        <Infos>
          <b>Note :</b> Les utilisateurs suivis d'un <AccountIcon /> et en tête de liste font partie de votre équipe.
        </Infos>
      </StyledHeadings>
      <Row style={{ display: 'flex', marginBottom: '1rem' }}>
        <StyledColumns nb={5}>
          <StyledH4>Utilisateurs référencés</StyledH4>
          <div>
            {
              currentUser.type === 'admin' &&
              <div>
                <StyledH5>Administrateurs</StyledH5>
                <Ul>
                  {admins.length ? admins.map(user =>
                    <React.Fragment key={user.id}>
                      <StyledCard
                        nom={user.firstname + " " + user.lastname}
                        email={user.email}
                        user={user}
                        onEdit={() => setToUpdate(user)}
                        isActive={toUpdate.id === user.id}
                      />
                    </React.Fragment >
                  )
                    : <Empty>Aucun utilisateur</Empty>}
                </Ul>
              </div>
            }
            <StyledH5>Utilisateurs</StyledH5>
            {
              usersNotAdmin.length
                ? <Ul>
                  {usersNotAdmin
                    .sort(user => user.manager === currentUser.id ? -1 : 1)
                    .map(user => {
                      return <React.Fragment key={user.id}>
                        <StyledCard
                          nom={user.firstname + " " + user.lastname}
                          email={user.email}
                          user={user}
                          onEdit={() => setToUpdate(user)}
                          isActive={toUpdate.id === user.id}
                        />
                      </React.Fragment>
                    }
                    )}
                </Ul>
                : <Empty>Aucun utilisateur</Empty>
            }
            {
              deletedUsers.length > 0 && currentUser.type === 'admin' && <>
                <StyledH5>Utilisateurs supprimé</StyledH5>
                <Ul>
                  {deletedUsers
                    .map(user => {
                      return <React.Fragment key={user.id}>
                        <StyledCard
                          nom={maskString(user.firstname) + " " + maskString(user.lastname)}
                          email={maskString(user.email, 3)}
                          user={{ ...user, active: false }}
                          onEdit={() => {
                            services
                              .updateUser(user.id, { ...user, deletedAt: null }).then(() => {
                                refreshUsers()
                              })
                          }}
                          canClickCard={false}
                          isActive={false}
                          labelButton={'Restaurer'}
                        />
                      </React.Fragment>
                    }
                    )}
                </Ul>
              </>
            }
          </div>
        </StyledColumns>
        <StyledColumns nb={7}>
          <Form onSubmit={handleSubmit}>
            <SubHeadings>
              <StyledH4>
                {(toUpdate?.id ? 'Modifier' : 'Ajouter') + ' un utilisateur'}
              </StyledH4>
              {
                toUpdate?.id
                  ? <CancelButton onClick={handleClickCancel}>Annuler</CancelButton>
                  : <ResetButton onClick={handleClickReset}>Réinitialiser</ResetButton>
              }
            </SubHeadings>
            <FormGroup>
              <TextInput
                buttonIcon={EditIcon}
                value={toUpdate.firstname}
                onChange={firstname => setToUpdate({ ...toUpdate, firstname })}
                label='Prénom'
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                buttonIcon={EditIcon}
                value={toUpdate?.lastname}
                onChange={lastname => setToUpdate({ ...toUpdate, lastname })}
                label='Nom'
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                buttonIcon={EditIcon}
                value={toUpdate?.email}
                onChange={email => setToUpdate({ ...toUpdate, email })}
                label='Email'
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                buttonIcon={EditIcon}
                value={toUpdate?.company}
                onChange={company => setToUpdate({ ...toUpdate, company })}
                label='Entreprise'
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                value={toUpdate?.employeeId}
                buttonIcon={EditIcon}
                onChange={employeeId => setToUpdate({ ...toUpdate, employeeId })}
                label='N° de poste'
              />
            </FormGroup>
            <FormGroup inline={false}>
              <P style={{ fontWeight: 'bold' }}>Actif : </P>
              <div style={{ display: 'flex' }}>
                <div style={{ margin: '0.25rem' }}>
                  <Radio
                    name='user-activate'
                    label='Actif'
                    value='activate'
                    style={{ margin: '0.5rem' }}
                    checked={toUpdate.active}
                    onClick={() => setToUpdate({ ...toUpdate, active: true })}
                  />
                </div>
                <div style={{ margin: '0.25rem' }}>
                  <Radio
                    name='user-activate'
                    label='Désactivé'
                    value='Desactivate'
                    checked={!toUpdate.active}
                    onClick={() => setToUpdate({ ...toUpdate, active: false })}
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup inline={false}>
              <P style={{ fontWeight: 'bold' }}>Rôle : </P>
              <div style={{ display: 'flex' }}>
                <div style={{ margin: '0.25rem' }}>
                  <Radio
                    name='user-type'
                    label='Administrateur'
                    value='adminType'
                    style={{ margin: '0.5rem' }}
                    checked={(toUpdate.type === 'admin')}
                    onClick={() => setToUpdate({ ...toUpdate, type: 'admin' })}
                  />
                </div>
                <div style={{ margin: '0.25rem' }}>
                  <Radio
                    name='user-type'
                    label='Utilisateur'
                    value='userType'
                    checked={(toUpdate.type === 'user')}
                    onClick={() => setToUpdate({ ...toUpdate, type: 'user' })}
                  />
                </div>
              </div>
              <br />
              <P style={{ fontWeight: 'bold' }}>Gestion des droits : </P>
              <ul style={{ marginTop: '0', paddingLeft: '1rem', width: '-webkit-fill-available' }}>
                {featureStates?.filter(featureState => featureState?.label).sort((a, b) => a.label.localeCompare(b.label)).map(feature => {
                  if (toUpdate.type === 'admin') {
                    feature.isActive = true
                  } else {
                    feature.isActive = toUpdate.env && toUpdate.env[feature.key] ? toUpdate.env[feature.key] : false
                  }
                  if (feature.valueIsChange) {
                    feature.isActive = !feature.isActive
                  }
                  return <React.Fragment key={feature.key}>{feature.label && <li key={feature.key} style={{
                    marginBottom: '10px', listStyle: 'none', borderBottom: '1px solid #ddd', paddingBottom: '1rem'
                  }}>
                    <span>{feature.label}</span>
                    <Button
                      disabled={toUpdate.type === 'admin' || currentUser.type === 'user'}
                      type="button"
                      style={{
                        backgroundColor: `${feature.isActive ? "#92de42" : 'red'}`,
                        marginLeft: '10px',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '5px',
                        border: 'none',
                        cursor: 'pointer',
                        display: 'flex',
                        float: 'inline-end'
                      }}
                      onClick={() => {
                        setFeatureStates((preValue) => {

                          let featuresToUpdate = JSON.parse(JSON.stringify([...preValue]))
                          let featuresResult = []

                          featuresToUpdate.map(featureItem => {
                            let featureItemResult = JSON.parse(JSON.stringify({ ...featureItem }))
                            if (featureItemResult.key === feature.key) {
                              if (feature.valueIsChange == null) {
                                featureItemResult = {
                                  ...featureItemResult,
                                  valueIsChange: true
                                }
                              } else {
                                featureItemResult = {
                                  ...featureItemResult,
                                  valueIsChange: null
                                }
                              }
                            }
                            featuresResult.push(JSON.stringify({ ...featureItemResult }))
                          })
                          let resultJson = []
                          featuresResult.forEach(featureItemJson => {
                            resultJson.push(JSON.parse(featureItemJson))
                          })
                          return JSON.parse(JSON.stringify(resultJson))
                        })
                      }}
                    >
                      {feature.isActive ? "Activé" : "Désactivé"}
                    </Button>
                  </li>}
                  </React.Fragment>
                }
                )}
              </ul>

            </FormGroup>
            {!toUpdate.id && <><div> Une fois le nouvel l'utilisateur créer son mot de passe temporaire sera 'password' </div><br /></>}
            <ButtonsGroup>
              <Button primary type="submit" style={{ marginRight: '1rem' }}>Valider</Button>
              {toUpdate.id && <Button danger onClick={() => handleClickDelete(toUpdate?.id)}>Supprimer l'utilisateur</Button>}
            </ButtonsGroup>
          </Form>
        </StyledColumns>
      </Row>
    </StyledPage>
  )
}


const stylesCard = {
  card: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#fff',
    marginBottom: '1rem'
  },
  icon: {
    position: 'relative',
    top: '10px',
    left: '90%',
    width: '10px',
    height: '10px'
  },
  header: {
    textAlign: 'center',
    marginBottom: '16px'
  },
  name: {
    margin: '0',
    fontSize: '1.5em',
    color: '#333'
  },
  poste: {
    margin: '0',
    fontSize: '1.2em',
    color: '#555'
  },
  contact: {
    marginTop: '16px'
  },
  contactItem: {
    margin: '4px 0',
    color: '#333'
  }
};

const StyledCard = ({ nom, poste, email, telephone, user, onEdit, isActive, labelButton, canClickCard = true }) => {
  return (
    <div onClick={() => { if (canClickCard) onEdit(user.id) }} style={{ ...stylesCard.card, backgroundColor: isActive ? '#92de4238' : 'white', color: isActive ? 'white' : 'black' }}>
      <div style={stylesCard.icon}>
        <div style={{
          width: '100%',
          height: '100%',
          borderRadius: '50%', backgroundColor: user.active ? 'green' : 'red'
        }} />
      </div>
      <div style={stylesCard.header}>
        <h2 style={stylesCard.name}>{nom}</h2>
        <h4 style={stylesCard.poste}>{poste}</h4>
      </div>
      <div style={stylesCard.contact}>
        <p style={stylesCard.contactItem}><strong>Email:</strong> {email}</p>
        {telephone && <p style={stylesCard.contactItem}><strong>Téléphone:</strong> {telephone}</p>}
      </div>
      <Button style={{ display: 'block', margin: 'auto' }} disabled={isActive} onClick={() => onEdit(user.id)}>
        {labelButton || 'Modifier'}
      </Button>
    </div>
  );
};