import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    TextInput,
    Select,
    Option,
    Ul,
    Li,
    Button,
    TeamIcon,
    Table,
    THead,
    Tr,
    Th,
    TBody,
    Td,
} from '../../components';
import * as services from '../../services';
import styled from 'styled-components';
import useList from '../../hooks/useList';

const StyledTable = styled(Table)`
  width: 100%;
  margin-bottom: 2rem;

  a {
    color: ${({ theme }) => theme.black};

    &:hover {
      color: ${({ theme }) => theme.primaryColor};
      text-decoration: underline;
    }
  }
`

const StyledSearchInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`

const TeamIconWrapper = styled.span`
  line-height: 0;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: .5rem;
`

const StyledTeamIcon = styled(TeamIcon)`
  width: 1.8rem;
  height: 1.8rem;
`

const ManageTeam = ({
    teamData,
    users,
    refreshTeam,
    localUser,
}) => {
    const [teamName, setTeamName] = useState(teamData.name);
    const [newMemberId, setNewMemberId] = useState('');
    const [addMemberError, setAddMemberError] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [{ items: projects }, { setItems: setProjects }] = useList();

    const canEdit = localUser.type === 'admin' || teamData.manager.includes(localUser.id) || teamData.manager.includes(localUser._id);

    useEffect(() => {
        services.fetchAllProjects().then(({ projects }) => setProjects(projects));
    }, []);

    useEffect(() => {
        setTeamName(teamData.name);
    }, [teamData]);

    const handleAddMember = () => {
        if (!newMemberId) {
            setAddMemberError('Veuillez sélectionner un utilisateur valide.');
            return;
        }
        services
            .updateTeam(
                teamData.id,
                {
                    ...teamData,
                    teammates: [
                        newMemberId,
                        ...teamData.teammates.map(t => t.id)
                    ]
                }
            )
            .then(() => {
                setNewMemberId('');
                refreshTeam();
            })
            .catch(err => {
                console.error(err);

                if (err.response.status === 500)
                    setAddTeamUserError('Erreur API');
                else if (err.response.status === 412)
                    setAddTeamUserError("Opération invalid");
                else if (err.response.status === 409)
                    setAddTeamUserError("Utilisateur déja dans l'équipe");
                else if (err.response.status === 404)
                    setAddTeamUserError("Utilisateur introuvable");

                setOnAddTeamUser(false);
            });
    };

    const handleToggleManager = (userId) => {
        const isCurrentlyManager = teamData.manager.includes(userId);
        const updatedManagers = isCurrentlyManager
            ? teamData.manager.filter(id => id !== userId)
            : [...teamData.manager, userId];

        services.updateTeam(teamData.id, {
            ...teamData,
            manager: updatedManagers
        })
            .then(() => refreshTeam())
            .catch(err => console.error('Erreur lors de la mise à jour des managers :', err));
    };

    const handleToggleProject = (projectId) => {
        const isPresentProject = teamData.projects && teamData.projects.includes(projectId);
        const updatedProjects = isPresentProject && teamData.projects
            ? teamData.projects.filter(id => id !== projectId)
            : [...teamData.projects, projectId];

        services.updateTeam(teamData.id, {
            ...teamData,
            projects: updatedProjects
        })
            .then(() => refreshTeam())
            .catch(err => console.error('Erreur lors de la mise à jour des projets :', err));
    };

    const handleDeleteTeammate = teammate => {
        services
            .updateTeam(
                teamData.id,
                {
                    ...teamData,
                    teammates: teamData.teammates.filter(
                        _teammate => _teammate.id !== teammate
                    )
                }
            )
            .then(() => refreshTeam());
    };

    const handleUpdateTeamName = () => {
        services
            .updateTeam(teamData.id, { ...teamData, name: teamName })
            .then(() => {
                refreshTeam();
            })
            .catch(err => {
                console.error(err);

                if (err.response.status === 500)
                    setEditTeamNameError('Erreur API');
                else if (err.response.status === 409)
                    setEditTeamNameError("Nom d'équipe existant (" + teamName + ")");
                else if (err.response.status === 412)
                    setEditTeamNameError("Nom d'équipe invalid");
            });
    };

    const filteredProjects = projects.filter(project =>
        project.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div style={{ padding: '1rem' }}>
            {/* Nom de l'équipe */}
            <div style={{ marginBottom: '1rem' }}>
                <h3>Nom de l'équipe</h3>
                <div style={{ display: 'flex' }}>
                    <TextInput
                        id="teamName"
                        value={teamName}
                        onChange={value => setTeamName(value)}
                        disabled={!canEdit}
                    />
                    {canEdit && <Button style={{ marginLeft: '1rem' }} primary onClick={handleUpdateTeamName}>
                        Renommer
                    </Button>}
                </div>
            </div>

            {/* Membres */}
            <div>
                <h3>Membres</h3>
                {/* Dropdown pour ajouter un membre */}
                {canEdit && <div>
                    <div style={{ display: 'flex' }}>
                        <Select style={{ width: '80%' }} placeholder={'-- Sélectionner un utilisateur --'} onChange={value => setNewMemberId(value)}>
                            {users.filter(user => !teamData.teammates.find(teammate => teammate.id === user.id)).map(user => (
                                <Option key={user.id} value={user.id} selected={newMemberId === user.id}>{`${user.firstname} ${user.lastname}`}</Option>
                            ))}
                        </Select>
                        <Button onClick={handleAddMember} style={{ marginLeft: 'auto' }}>Ajouter</Button>
                    </div>
                    {addMemberError && <div style={{ color: 'red' }}>{addMemberError}</div>}
                </div>}
                {/* Liste des membres */}
                <Ul style={{ padding: 0, listStyle: 'none', marginTop: '1rem' }}>
                    {teamData.teammates.map(member => {
                        const memberIsManager = teamData.manager.includes(member.id);
                        return <Li key={member.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0.5rem', backgroundColor: '#fff', marginBottom: '0.5rem', borderRadius: '4px', border: '1px solid #ddd' }}>
                            <div style={{ width: '1rem' }}>
                                {memberIsManager && <TeamIconWrapper>
                                    <StyledTeamIcon />
                                </TeamIconWrapper>}
                            </div>
                            <span>{member.firstname} {member.lastname}</span>
                            {canEdit && <div>
                                <Button onClick={() => handleToggleManager(member.id)} primary={memberIsManager} style={{ marginRight: '0.5rem' }}>
                                    {memberIsManager ? 'Définir comme utilisateur' : 'Définir comme manager'}
                                </Button>
                                <Button danger onClick={() => handleDeleteTeammate(member.id)} style={{ color: 'white' }}>
                                    Supprimer
                                </Button>
                            </div>}
                        </Li>;
                    })}
                </Ul>
            </div>
            <div>
                <h3>Projet Associé : </h3>
                <StyledSearchInput
                    type="text"
                    placeholder="Rechercher un projet..."
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                />
                <StyledTable>
                    <THead>
                        <Tr>
                            <Th>
                                Nom
                            </Th>

                            <Th>
                                Action
                            </Th>
                        </Tr>
                    </THead>
                    <TBody>
                        {filteredProjects.map(project => (
                            <Tr key={project.id}>
                                <Td>{project.name}</Td>
                                <Td>
                                    {canEdit && <Button
                                        danger={!teamData.projects.includes(project.id)}
                                        primary={teamData.projects.includes(project.id)}
                                        onClick={() => handleToggleProject(project.id)} style={{ color: 'white' }}>
                                        {teamData.projects.includes(project.id) ? `Dissocier de l'équipe` : `Lier à l'équipe`}
                                    </Button>}
                                </Td>
                            </Tr>
                        ))}
                    </TBody>
                </StyledTable>
            </div>

        </div>
    );
};

ManageTeam.propTypes = {
    teamData: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    refreshTeam: PropTypes.func.isRequired,
    localUser: PropTypes.object.isRequired,
};

export default ManageTeam;
