import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'qs';
import AuthContext from '../../contexts/AuthContext'

import useList from '../../hooks/useList';
import * as services from '../../services';

import {
    Section,
    H1,
    P,
    Form,
    SearchInput,
    TextInput,
    Select,
    Option,
    AddCircleFilledIcon,
    Pagination,
    ConfirmationModal,
    SpinnerAnimatedIcon
} from '../../components';
import { Team } from './Teams';
import ManageTeam from './ManageTeam';

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSection = styled(Section)`
  padding: 0 4%;
  flex: 1;
  height: 85%;
`;

const StyledFilters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const StyledSearchInput = styled(SearchInput)`
  width: 300px;
  margin-right: 1rem;
`;

const EmptyHelp = styled.div`
  display: flex;
  margin: 2rem 0;
  justify-content: center;
  text-align: center;
`;

export default () => {
    const [{ user }] = useContext(AuthContext)
    const nbItemsPerPage = 12;
    const location = useLocation();
    const [query, setQuery] = useState(qs.parse(location.search.replace(/\^\?/, '')));
    const [onLoading, setOnLoading] = useState(true);
    const [order, setOrder] = useState(['createdAt', 'asc']);
    const [activePageIndex, setActivePageIndex] = useState(1);
    const [onCreateTeam, setOnCreateTeam] = useState(false);
    const [createTeamError, setCreateTeamError] = useState('');
    const [newTeamName, setNewTeamName] = useState('');
    const formRef = useRef(null);
    const [toDeleteTeam, setToDeleteTeam] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [users, setUsers] = useState([]);  // Nouvel état pour les utilisateurs

    const [
        { items: teams },
        {
            setItem: setTeam,
            setItems: setTeams,
            updateItem: updateTeam,
            deleteItem: deleteTeam,
            clearItem: clearTeams,
        }
    ] = useList();

    useEffect(() => {
        services.fetchAllUsers().then(({ users }) => { setUsers(users) }).catch(console.error);

        services.fetchAllTeams().then(({ teams }) => {
            setTeams(teams);
            setOnLoading(false);
        }).catch(err => {
            console.error(err);
            setOnLoading(false);
        });
    }, []);

    const handleCreateTeam = e => {
        setCreateTeamError('');
        if (!newTeamName) return;
        setOnCreateTeam(true);

        services.createTeam({ name: newTeamName })
            .then(({ team }) => {
                setTeam(team);
                setNewTeamName('');
                setOnCreateTeam(false);
            })
            .catch(err => {
                console.error(err);

                if (err.response?.status === 409) setCreateTeamError("L'équipe existe déjà");
                else if (err.response?.status === 500) setCreateTeamError('Erreur API');
                else if (err.response?.status === 412) setCreateTeamError("Nom d'équipe invalide");

                setOnCreateTeam(false);
            });
    };

    const handleClickDeleteTeam = team => setToDeleteTeam(team);

    const handleConfirmDeleteTeam = id => {
        services.deleteTeam(id).then(() => deleteTeam(id));
        setToDeleteTeam(null);
    };

    const handleCancelDeleteTeam = () => setToDeleteTeam(null);

    const selectTeamById = teamId => {
        const team = teams.find(t => t.id === teamId);
        if (team) setSelectedTeam(team);
    };

    const filterTeams = (teams) => {
        let result = teams;
        if (query.name) {
            result = teams.filter(team => team.name.toLowerCase().includes(query.name.toLowerCase()))
        };
        return result;
    }

    const offset = (activePageIndex - 1) * nbItemsPerPage;

    return (
        <>
            <H1 style={{ margin: '2rem 0' }}>Gérer les équipes</H1>
            <StyledSection>
                <StyledFilters>
                    <div>
                        <StyledSearchInput
                            placeholder="Rechercher"
                            value={query.name || ''}
                            onChange={name => setQuery({ name })}
                        />
                        <Select onChange={order => setOrder(order)}>
                            <Option value={['name', 'desc']} selected={['name', 'desc'].every((v, i) => v === order[i])}>{'Nom DESC (A -> Z)'}</Option>
                            <Option value={['name', 'asc']} selected={['name', 'asc'].every((v, i) => v === order[i])}>{'Nom ASC (Z -> A)'}</Option>
                            <Option value={['createdAt', 'asc']} selected={['createdAt', 'asc'].every((v, i) => v === order[i])}>{'Date de création (Récent -> Ancien)'}</Option>
                            <Option value={['createdAt', 'desc']} selected={['createdAt', 'desc'].every((v, i) => v === order[i])}>{'Date de création (Ancien -> Récent)'}</Option>
                        </Select>
                    </div>
                    <Form onSubmit={handleCreateTeam}>
                        <TextInput
                            ref={formRef}
                            name="name"
                            value={newTeamName}
                            placeholder="Créer une équipe"
                            buttonIcon={onCreateTeam ? SpinnerAnimatedIcon : AddCircleFilledIcon}
                            onChange={e => {
                                setNewTeamName(e);
                                setCreateTeamError('');
                            }}
                            onClick={handleCreateTeam}
                            disabled={onCreateTeam}
                        />
                        {createTeamError && <div style={{ color: 'red' }}>{createTeamError}</div>}
                    </Form>
                </StyledFilters>
                {!onLoading && !teams.length && (
                    <EmptyHelp>
                        <P>
                            Il semble que vous n'ayez aucune équipe.<br />
                            Vous pouvez <button onClick={() => formRef.current.focus()}>ajouter une équipe</button>.
                        </P>
                    </EmptyHelp>
                )}
                {onLoading ? (
                    <StyledLoading>
                        <SpinnerAnimatedIcon /> &nbsp; Chargement ...
                    </StyledLoading>
                ) : (
                    <>
                        <div style={{ display: 'flex', height: 'inherit' }}>
                            <div style={{ display: 'block', width: selectedTeam ? '25%' : '100%', borderRight: selectedTeam ? '1px solid #ccc' : '' }}>
                                {filterTeams(teams)
                                    .sort((a, b) => (order[1] === 'asc' ? (a[order[0]] > b[order[0]] ? -1 : 1) : (a[order[0]] < b[order[0]] ? -1 : 1)))
                                    .slice(offset, offset + nbItemsPerPage)
                                    .map(team => (
                                        <Team
                                            key={`team-${team.id}`}
                                            team={team}
                                            canEdit={user.type === 'admin' || team.manager.includes(user.id)}
                                            onClickDelete={() => handleClickDeleteTeam(team)}
                                            onClick={() => selectTeamById(team.id)}
                                        />
                                    ))}
                            </div>
                            {selectedTeam && <div style={{ width: '75%' }}>
                                <ManageTeam
                                    teamData={{ ...selectedTeam }}
                                    users={users}
                                    localUser={user}
                                    refreshTeam={() => {
                                        clearTeams();
                                        setOnLoading(true);
                                        services.fetchAllTeams().then(({ teams }) => {
                                            setTeams(teams);
                                            setSelectedTeam(teams.find(team => team.id === selectedTeam.id))
                                            setOnLoading(false);
                                        }).catch(err => {
                                            console.error(err);
                                            setOnLoading(false);
                                        });
                                    }}
                                />

                            </div>
                            }
                        </div>
                        <Pagination
                            nbItemsPerPage={nbItemsPerPage}
                            nbPagesDisplayed={5}
                            activePageIndex={activePageIndex}
                            totalItems={filterTeams(teams).length}
                            onChange={i => setActivePageIndex(i)}
                        />
                    </>
                )}
            </StyledSection>
            {toDeleteTeam && (
                <ConfirmationModal
                    isOpen
                    danger
                    title="Êtes-vous sûr de vouloir faire ça ?"
                    description={`Supprimer l'équipe ${toDeleteTeam.name}. Cette action est irreversible.`}
                    onConfirm={() => handleConfirmDeleteTeam(toDeleteTeam.id)}
                    onCancel={handleCancelDeleteTeam}
                />
            )}
        </>
    );
};
