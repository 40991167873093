import React, { useReducer } from 'react'

let initialState = {
  loading: false,
  items: []
}

const reducer = ({ items, ...state }, { type, payload }) => {
  const index = payload?.id ? items.findIndex(i => i.id === payload.id) : -1

  switch (type) {
    case 'RESET_ITEMS':
      return {
        ...state,
        items: payload.items,
      }
    case 'SET_ITEM':
      return {
        ...state,
        items: [...items, payload.item]
      }
    case 'SET_ITEMS':
      return {
        ...state,
        items: [...items, ...payload.items]
      }
    case 'UPDATE_ITEM':
      items[index] = { ...items[index], ...payload.updates }

      return {
        ...state,
        items
      }
    case 'UPDATE_ITEMS':
      const ids = payload.ids || items.map(i => i.id)

      return {
        ...state,
        items: items.map(item => ids.indexOf(item.id) >= 0 ? ({ ...item, ...payload.updates }) : item)
      }
    case 'DELETE_ITEM':
      return {
        ...state,
        items: items.filter(item => item.id !== payload.id)
      }
    case 'CLEAR_ITEM':
      return {
        ...state,
        items: []
      }
    default:
      throw new Error()
  }
}

export default () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const actions = {
    resetItems: items => dispatch({ type: 'RESET_ITEMS', payload: { items } }),
    setItem: item => dispatch({ type: 'SET_ITEM', payload: { item } }),
    setItems: items => dispatch({ type: 'SET_ITEMS', payload: { items } }),
    updateItem: (id, updates) =>
      dispatch({ type: 'UPDATE_ITEM', payload: { id, updates } }),
    updateItems: (updates, ids = null) =>
      dispatch({ type: 'UPDATE_ITEMS', payload: { ids, updates } }),
    deleteItem: id => dispatch({ type: 'DELETE_ITEM', payload: { id } }),
    clearItem: () => dispatch({ type: 'CLEAR_ITEM' })
  }

  return [state, actions]
}
