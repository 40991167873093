import React, { useContext } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import AuthContext from '../contexts/AuthContext'
import Dictionaries from './Dictionaries'
import Documents from './Documents'
import Profiles from './Profiles'
import Projects from './Projects'
import Templates from './Templates'

import { Tabs } from '../components'

const StyledTabs = styled(Tabs)`
  width: 100%;
  height: 100%;
`

export default (props) => {
  const history = useHistory()
  const { resource } = useParams()
  const [{ user }] = useContext(AuthContext)
  const defaultTabs = [
    {
      key: 'projects',
      title: 'Gestion des projets',
      content: <Projects {...props} />,
      envKey: 'FEATURE_MANAGE_PROJECT'
    },
    {
      key: 'documents',
      title: 'Gestion des documents',
      content: <Documents {...props} />,
      envKey: 'FEATURE_MANAGE_DOCUMENTS'
    },
    {
      key: 'dictionaries',
      title: 'Gestion des dictionnaires métiers',
      content: <Dictionaries {...props} />,
      envKey: 'FEATURE_MANAGE_DICTIONNARY'
    },
    {
      key: 'templates',
      title: 'Gestion des templates',
      content: <Templates {...props} />,
      envKey: 'FEATURE_MANAGE_TEMPLATES'
    },
    {
      key: 'profiles',
      title: 'Gestion des profils',
      content: <Profiles {...props} />,
      envKey: 'FEATURE_MANAGE_EXPORT_PROFILE'
    }
  ]

  return (
    <StyledTabs
      currentTab={resource}
      panes={defaultTabs.filter(tab => {
        return user.env[tab.envKey]
      })}
      onChange={v => history.push(`/manage/${v}`)}
    />
  )
}
